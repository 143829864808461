/* global getCookie */

const formWrapperEl = document.querySelector('.block--contact-request__form');
const formEl = formWrapperEl.querySelector('#submit-contact-request');
const successEl = document.querySelector('.block--contact-request__success');
const interestEl = document.querySelector('#id_interest');

const succ = (data) => {

  const interest_options = window.CONTACT_REQUEST_INTEREST_OPTIONS;

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    category: 'B2B-yhteydenotto',
    action: `Send message ${interest_options[data.interest]}`,
    label: 'Lähetä viesti',
    event: 'sendContactRequest',
  })
  formWrapperEl.style.display = 'none';
  successEl.style.display = 'block';
  successEl.scrollIntoView();
}

const err = (err) => {
  // TODO(Petri): Don't ignore errors
}

const submitContactRequest = e => {
  e.preventDefault();

  const formData = new FormData(formEl);

  // Create a javascript object from formData
  const data = {};
  for (let [key, value] of formData.entries()) {
    data[key] = value;
  }

  fetch(formEl.dataset.url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': getCookie('csrftoken'),
    },
    credentials: 'same-origin',
    body: JSON.stringify(data),
  })
    .then(response => response.json())
    .then(result => result.ok ? succ(data) : err(result))
    .catch(err);
}

if (formEl) {
  formEl.addEventListener('submit', submitContactRequest);
}

// Set default value for interest based on cms contents
if (interestEl) {
  // Get var from template
  const interest = window.CONTACT_REQUEST_DEFAULT_INTEREST || 0;

  if (interest) {
    interestEl.value = interest;
  }
}